import * as React from 'react'
import { Box, Chip, Stack, TextField } from '@mui/material'
import produce from 'immer'
import { FormattedMessage } from 'react-intl'

export default function TagSelector({ item, setState }) {
  return (
    <Stack direction="row" spacing={2} sx={{ my: 2 }} alignItems="center">
      <Box>
        <FormattedMessage id="tag.header" />
      </Box>
      <FormattedMessage id="tag.enter_tags">
        {(placeholder) => (
          <TextField
            variant="standard"
            size="small"
            sx={{
              margin: '1rem 0',
              '.stack': {
                my: 1
              }
            }}
            margin="none"
            placeholder={placeholder}
            value={item?.tag}
            onChange={(e) => {
              setState(
                produce((draft) => {
                  const currentItem = draft.items.find((i) => i.id === item.id)
                  currentItem.tag = e.target.value
                })
              )
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === 'Enter') {
                setState(
                  produce((draft) => {
                    const currentItem = draft.items.find(
                      (i) => i.id === item.id
                    )
                    if (!currentItem.tags) {
                      currentItem.tags = [currentItem.tag]
                    } else {
                      currentItem.tags = [
                        ...new Set([...currentItem.tags, currentItem.tag])
                      ]
                    }
                    currentItem.tag = ''
                  })
                )
              }
            }}
            InputProps={{
              startAdornment: (
                <Stack
                  direction="row"
                  spacing={1}
                  className="stack"
                  sx={{ mr: 2 }}>
                  {item?.tags?.map((tag) => (
                    <Chip
                      label={tag}
                      onDelete={() => {
                        setState(
                          produce((draft) => {
                            const currentItem = draft.items.find(
                              (i) => i.id === item.id
                            )
                            currentItem.tags = currentItem.tags.filter(
                              (i) => i !== tag
                            )
                          })
                        )
                      }}
                    />
                  ))}
                </Stack>
              )
            }}
          />
        )}
      </FormattedMessage>
    </Stack>
  )
}
