import React, { useState } from 'react'
import { FormControl, MenuItem, Select } from '@mui/material'

import { CA, MX } from 'country-flag-icons/react/3x2'

const LanguageSelector = ({ locale, setLocale }) => {
  const [chosenLanguage, setChosenLanguage] = useState(locale)

  return (
    <div>
      <FormControl>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={chosenLanguage}
          onChange={(event) => {
            setLocale(event.target.value)
            setChosenLanguage(event.target.value)
          }}>
          <MenuItem value="en">
            <CA title="English" style={{ height: '15px' }} />
          </MenuItem>
          <MenuItem value="es">
            <MX title="Español" style={{ height: '15px' }} />
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}

export default LanguageSelector
