export const routes = {
  login: '/',
  surveys: '/surveys',
  collect: '/collect',
  surveyNew: '/new',
  edit: '/edit',
  respond: '/respond',
  preview: '/preview'
}

export const survey_item_types = {
  openText: 'open-text',
  pageBreak: 'page-break',
  singleChoice: 'single-choice',
  text: 'text-content',
  likertScale: 'likert-scale',
  questions: ['open-text', 'single-choice', 'likert-scale']
}

export const colors = {
  main: '#00418F',
  mainLight: '#0065E0',
  white: 'white',
  success: '#008f2d',
  disabled: '#858585',
  config: '#828c70',
  grey: '#F2F3F8',
  lavenderWeb: '#D7DBEA',
  darkgrey: 'rgba(11,34,63,0.84)',
  darkBlue: '#002356',
  green: '#008f2d',
  lightgreen: '#78C091',
  red: '#FF785A',
  greyhighlight: '#E4E7F1'
}

export const defaultFromEmail = 'surveys@prompta.ai'
