import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Container as Cont,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { survey_item_types } from '../constants'
import api from '../api'
import Loading from '../components/Loading'
import RadioList from '../components/RadioList'
import RichText from '../components/Editor'
import Store from '../store'
import styled from '@emotion/styled'
import { isMobile } from 'react-device-detect'
import debounce from 'lodash.debounce'
import { useSnackbar } from 'notistack'

const StyledBox = styled(Box)`
  background-image: url(${(props) => props.meta.bgimage || 'unset'});
  background-size: cover;
  height: 100vh;
  .middle-container {
    background-color: ${(props) => props.meta.bgcolor || 'rgb(231,231,231)'};
    height: 100vh;
    overflow-y: scroll;
    padding-top: 25px;
    box-sizing: border-box;
  }
`

const Container = styled(Cont)`
  padding: 30px 15px;

  color: ${(props) => props?.meta?.fontcolor || 'unset'};
  .question-wrapper {
    background-color: ${(props) => props?.meta?.questionbgcolor || 'white'};

    .choices {
      /* Font & Text */
      font-size: 18px;
      font-style: normal;
      font-variant: normal;
      font-weight: 400;
      letter-spacing: normal;
      line-height: 27px;
      text-decoration: none solid rgb(5, 70, 126);
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      vertical-align: baseline;
      white-space: normal;
      word-spacing: 0px;

      /* Color & Background */
      background-attachment: scroll;
      background-color: rgba(0, 0, 0, 0);
      background-image: none;
      background-position: 0% 0%;
      background-repeat: repeat;
      color: ${(props) => props?.meta?.fontcolor || 'rgb(5, 70, 126)'};
    }

    .title {
      /* Font & Text */
      font-size: 22px;
      font-style: normal;
      font-variant: normal;
      font-weight: 500;
      letter-spacing: normal;
      line-height: 27.5px;
      text-decoration: none solid rgb(5, 70, 126);
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      vertical-align: baseline;
      white-space: normal;
      word-spacing: 0px;

      /* Color & Background */
      background-attachment: scroll;
      background-color: rgba(0, 0, 0, 0);
      background-image: none;
      background-position: 0% 0%;
      background-repeat: repeat;
      color: ${(props) => props?.meta?.fontcolor || 'rgb(5, 70, 126)'};

      /* Box */
      height: auto;
      width: auto;
      border: 0px none rgb(5, 70, 126);
      border-top: 0px none rgb(5, 70, 126);
      border-right: 0px none rgb(5, 70, 126);
      border-bottom: 0px none rgb(5, 70, 126);
      border-left: 0px none rgb(5, 70, 126);
      margin: 0px;
      padding: 0px;
      max-height: none;
      min-height: 0px;
      max-width: none;
      min-width: 0px;
    }
  }

  .survey-title {
    /* Font & Text */
    font-size: 26px;
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 32.5px;
    text-decoration: none solid rgb(5, 70, 126);
    text-align: start;
    text-indent: 0px;
    text-transform: none;
    vertical-align: baseline;
    white-space: normal;
    word-spacing: 0px;

    /* Color & Background */
    background-attachment: scroll;
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    background-position: 0% 0%;
    background-repeat: repeat;
    color: ${(props) => props?.meta?.fontcolor || 'rgb(5, 70, 126)'};

    /* Box */
    height: 32.4913px;
    width: 895.998px;
    border: 0px none rgb(5, 70, 126);
    border-top: 0px none rgb(5, 70, 126);
    border-right: 0px none rgb(5, 70, 126);
    border-bottom: 0px none rgb(5, 70, 126);
    border-left: 0px none rgb(5, 70, 126);
    margin: 0px;
    padding: 0px;
    max-height: none;
    min-height: 0px;
    max-width: none;
    min-width: 0px;
  }
`

const NavBtn = styled(Button)`
  background-color: ${(props) => props?.meta?.fontcolor || 'white'};
`

const QuestionWrapper = (props) => {
  if (props.item.type === survey_item_types.pageBreak) {
    // Do not style page breaks
    return <></>
  }

  let questionTitle = Array.isArray(props.item.title)
    ? props.item.title[0].children
    : props.item.title

  if (props.numbering && props.item.questionNumber) {
    if (typeof props.item.title === 'string') {
      questionTitle = props.item.questionNumber + '. ' + questionTitle
    } else {
      questionTitle = [{ text: props.item.questionNumber + '. ' }].concat(
        questionTitle
      )
    }
  }

  return (
    <Box
      className="question-wrapper"
      sx={{
        my: isMobile ? 1 : 3,
        p: 2,
        borderRadius: 2
      }}>
      {props.item.title && (
        <Box
          className="title"
          sx={{
            pb: 2,
            borderBottom: '1px solid dimgrey'
          }}>
          {typeof props.item.title === 'string' ? (
            <>{questionTitle}</>
          ) : (
            <RichText value={questionTitle} readOnly />
          )}
        </Box>
      )}
      {props.item.content && (
        <RichText value={props.item.content[0].children} readOnly />
      )}
      <Box className="choices">{props.children}</Box>
    </Box>
  )
}

export default function Respond({ isPreview = false }) {
  const { jwt } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [state, setState] = useState(null)
  const [finished, setFinished] = useState(false)
  const [filterTags, setFilterTags] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  const answers = Store((state) => state.answers)
  const setAnswers = Store((state) => state.setAnswers)

  const previewData = Store((state) => state.previewData)
  const [page, setPage] = useState(0)
  const [pageArr, setPageArr] = useState([])
  const myRef = useRef(null)

  const isSurveyValid = (state, answers) => {
    if (!state || !state.structure) return false

    for (let item of state.structure) {
      if (survey_item_types.questions.includes(item.type)) {
        if (!answers[item.pk]) {
          return false
        }
      }
    }
    return true
  }

  const statusPopup = (text, style) => {
    enqueueSnackbar(text, {
      variant: style,
      preventDuplicate: true,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      }
    })
  }

  const checkFilters = (_state, _answers) => {
    _state = _state || state
    _answers = _answers || answers
    let totalTags = []
    if (!_state) return []
    for (let q in _answers) {
      if (typeof _answers[q] === 'number') {
        const item = _state.structure.find((i) => i.pk.toString() === q)
        if (!item) continue
        const choice = item.choices.find((i) => i.pk === _answers[q])
        if (!choice.tags) totalTags.push(...choice.tags)
      }
    }
    setFilterTags([...new Set(totalTags)])
    return totalTags
  }

  useEffect(() => {
    const unsub3 = Store.subscribe(
      (state) => state.answers,
      (answers) => {
        checkFilters(state, answers)
      }
    )
    return unsub3
  }, [state])

  useEffect(() => {
    if (!jwt) return

    const autoSaveAnswers = debounce(
      async (answers) => {
        const isAnswersEmpty =
          Object.keys(answers).length === 0 ||
          Object.values(answers).every(
            (value) => value === '' || value === null
          )

        if (isAnswersEmpty) {
          return
        } else if (Object.entries(answers).length > 0) {
          try {
            statusPopup('Saving now...', 'info')
            await api.saveAnswerTmp(jwt, answers)
            statusPopup('Answers saved successfully!', 'success')
          } catch (error) {
            statusPopup('Failed to save answers.', 'error')
          }
        }
      },
      2000,
      { leading: false, trailing: true }
    )

    const unsub3 = Store.subscribe((state) => state.answers, autoSaveAnswers)
    return unsub3
  }, [])

  useEffect(() => {
    ;(async () => {
      let newState = null
      if (jwt) {
        const { survey_data, saved_answers, msg } = await api.getSurveyJWT(jwt)
        newState = survey_data
        const initialAnswers = {}
        if (newState?.structure) {
          newState.structure.forEach((item) => {
            if (survey_item_types.questions.includes(item.type)) {
              initialAnswers[item.pk] = ''
            }
          })
        }
        setAnswers({ ...initialAnswers, ...saved_answers })
        if (msg) newState = { msg }
      } else {
        newState = previewData
      }
      if (newState?.structure && newState?.meta?.numbering) {
        let questionNumber = 1
        newState.structure = newState.structure.map((item) => {
          if (survey_item_types.questions.includes(item.type)) {
            return {
              ...item,
              questionNumber: questionNumber++
            }
          } else {
            return item
          }
        })
      }
      setState(newState)
      checkFilters(newState)
      if (!newState.msg) {
        let pageItemIndexes = []
        for (let i = 1; i < newState.structure.length; i++) {
          if (newState.structure[i].type === 'page-break')
            pageItemIndexes.push(i)
        }
        setPageArr(pageItemIndexes)
      }
    })()
  }, [])

  if (!state) return <Loading />

  if (state.msg)
    return (
      <Container meta={state?.meta} maxWidth="md">
        <Box>{state.msg}</Box>
      </Container>
    )

  const LogoComponent = () => {
    return (
      <div style={{ display: 'flex' }}>
        {Array.isArray(state.meta.logo) ? (
          state.meta.logo.map((image) => (
            <Box
              key={image}
              sx={{
                maxHeight: 120,
                maxWidth: '40vw',
                mixBlendMode: 'multiply',
                p: 4
              }}
              component="img"
              src={image}
              alt="logo"
            />
          ))
        ) : (
          <Box
            sx={{
              maxHeight: 120,
              maxWidth: '40vw',
              mixBlendMode: 'multiply',
              p: 4
            }}
            component="img"
            src={state.meta.logo}
            alt="logo"
          />
        )}
      </div>
    )
  }

  return (
    <StyledBox meta={state.meta}>
      <Box className="middle-container" ref={myRef}>
        <Container meta={state.meta} maxWidth="md">
          {finished ? (
            <Box>
              {state?.meta?.regards ||
                'We thank you for your participation, your results have been submitted.'}
            </Box>
          ) : (
            <>
              <LogoComponent />
              {page === 0 && (
                <Box sx={{ px: 2 }}>
                  <Typography variant="h5" className="survey-title">
                    {state.title}
                  </Typography>
                </Box>
              )}
              {state.structure
                .filter((i) => {
                  if (i.tags) {
                    for (let tag of i.tags) {
                      if (filterTags.includes(tag)) {
                        return false
                      }
                    }
                  }
                  return true
                })
                .slice(pageArr[page - 1] || 0, pageArr[page] || undefined)
                .map((item, idx) => {
                  let content
                  switch (item.type) {
                    case survey_item_types.text:
                      content = (
                        <Box>
                          {item.header && (
                            <Typography sx={{ mb: 2 }} variant="h5">
                              {item.header}
                            </Typography>
                          )}
                          {item.content && (
                            <RichText value={item.content} readOnly />
                          )}
                        </Box>
                      )
                      break
                    case survey_item_types.singleChoice:
                      content = (
                        <Box>
                          <RadioList
                            preChecked={answers[item.pk]}
                            checkedColor={state.meta?.fontcolor}
                            choices={item.choices}
                            onSelect={async (choice) => {
                              await setAnswers({
                                ...answers,
                                [item.pk]: choice.pk
                              })
                            }}
                          />
                        </Box>
                      )
                      break
                    case survey_item_types.openText:
                      content = (
                        <TextField
                          sx={{
                            mt: 2
                          }}
                          fullWidth
                          id="standard-multiline-static"
                          label=""
                          multiline
                          rows={2}
                          variant="standard"
                          value={answers[item.pk] || ''}
                          onChange={(event) =>
                            setAnswers({
                              ...answers,
                              [item.pk]: event.target.value
                            })
                          }
                        />
                      )
                      break
                    case survey_item_types.likertScale:
                      content = (
                        <FormControl style={{ width: '100%' }}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-form-control-label-placement"
                            name={`likert-${item.pk}`}
                            value={answers[item.pk] || ''}
                            onChange={(event) =>
                              setAnswers({
                                ...answers,
                                [item.pk]: parseInt(event.target.value)
                              })
                            }>
                            {item.scale.map((option) => (
                              <Box key={option.id} style={{ width: '20%' }}>
                                <FormControlLabel
                                  value={option.pk}
                                  control={<Radio />}
                                  label={option.title}
                                  labelPlacement="bottom"
                                  style={{
                                    alignContent: 'center',
                                    textAlign: 'center',
                                    width: '100%',
                                    margin: 0
                                  }}
                                />
                              </Box>
                            ))}
                          </RadioGroup>
                        </FormControl>
                      )
                      break
                    case survey_item_types.pageBreak:
                      content = <></>
                      break
                    default:
                      content = <>Unknown question type.</>
                  }
                  return (
                    <QuestionWrapper
                      item={item}
                      numbering={state.meta.numbering}
                      key={item.pk || 1000 * (1 + page) + idx}>
                      {content}
                    </QuestionWrapper>
                  )
                })}
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}>
                {page > 0 && (
                  <NavBtn
                    meta={state?.meta}
                    variant="contained"
                    sx={{ float: 'right' }}
                    onClick={async () => {
                      setTimeout(() => {
                        myRef.current.scrollTo({ top: 0, behavior: 'auto' })
                      }, 20)
                      setPage(page - 1)
                    }}>
                    {state.meta?.back_btn || 'Prev'}
                  </NavBtn>
                )}
                {page < pageArr.length && (
                  <NavBtn
                    meta={state?.meta}
                    variant="contained"
                    sx={{ float: 'right' }}
                    onClick={async () => {
                      setTimeout(() => {
                        myRef.current.scrollTo({ top: 0, behavior: 'auto' })
                      }, 20)
                      setPage(page + 1)
                    }}>
                    {state.meta?.next_btn || 'Next'}
                  </NavBtn>
                )}

                {page === pageArr.length && (
                  <NavBtn
                    meta={state?.meta}
                    variant="contained"
                    sx={{ float: 'right' }}
                    disabled={isPreview}
                    onClick={async () => {
                      if (!isSurveyValid(state, answers)) {
                        setErrorMessage(
                          'Please answer all required questions before submitting.'
                        )
                        return
                      }
                      setErrorMessage('')
                      if (jwt) {
                        try {
                          const response = await api.saveAnswer(jwt, answers)
                          if (response.success) {
                            setErrorMessage('')
                            setFinished(true)
                            let redirectLink = state.meta?.redirect
                            if (
                              !redirectLink.startsWith('https://') &&
                              !redirectLink.startsWith('http://')
                            ) {
                              redirectLink = 'https://' + redirectLink
                            }
                            window.location.href =
                              redirectLink || 'https://prompta.ai'
                          } else {
                            setErrorMessage(response.message)
                          }
                        } catch (error) {
                          setErrorMessage('An unexpected error occurred.')
                        }
                      }
                    }}>
                    {state.meta?.submit_btn || 'Submit'}
                  </NavBtn>
                )}
              </Stack>
            </>
          )}
          {errorMessage && (
            <Typography
              color="error"
              variant="body2"
              sx={{ textAlign: 'center', marginTop: '1rem' }}>
              <b>{errorMessage}</b>
            </Typography>
          )}
        </Container>
      </Box>
    </StyledBox>
  )
}
