import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { createBrowserHistory as createHistory } from 'history'
import { SnackbarProvider } from 'notistack'
import SurveyPage from './pages/Survey'
import { SurveyList } from './pages/Survey/SurveyList'
import { CollectorList } from './pages/Collect/CollectorList'
import CollectPage from './pages/Collect'
import CollectSurvey from './pages/Collect/CollectSurvey'
import Login from './pages/Login'
import { routes } from './constants'
import { SurveyEdit } from './pages/Survey/SurveyEdit'
import { SurveyNew } from './pages/Survey/SurveyNew'
import NoMatch from './pages/NoMatch'
import Respond from './pages/Respond'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { colors } from './constants'
import Store from './store'
import Loading from './components/Loading'
import Box from '@mui/material/Box'

const history = createHistory()

const theme = createTheme({
  palette: {
    primary: {
      main: colors.main
    },
    secondary: {
      main: colors.green
    }
  },
  status: {
    danger: colors.red
  }
})

function App({ locale, setLocale }) {
  // this effect logs changes in store
  const isAuthenticated = Store((state) => state.isAuthenticated)
  const setIsAuthenticated = Store((state) => state.setIsAuthenticated)
  const [loading, setLoading] = useState(true)

  const getSession = async () => {
    try {
      const res = await fetch('/user/session/', {
        credentials: 'same-origin'
      })
      const data = await res.json()

      if (data.isAuthenticated) {
        setIsAuthenticated(true)
      } else {
        setIsAuthenticated(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getSession()
      .then(() => {
        setLoading(false)
      })
      .catch((e) => console.log(e))
  }, [])

  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100vh',
          width: '100vw'
        }}>
        <Box
          sx={{
            fontSize: 50,
            color: '#00438e',
            my: 3,
            px: 3
          }}>
          Prompta.ai
        </Box>
        <Loading />
      </Box>
    )

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <Router history={history}>
          <Routes>
            <Route index element={<Login />} />
            <Route path={routes.respond + '/:jwt'} element={<Respond />} />
            {isAuthenticated && (
              <>
                <Route
                  path={routes.surveys}
                  element={
                    <SurveyPage locale={locale} setLocale={setLocale} />
                  }>
                  <Route index element={<SurveyList />} />
                  <Route path="new" element={<SurveyNew />} />
                  <Route path=":surveyId" element={<SurveyEdit />} />
                  <Route
                    path="preview"
                    element={<Respond isPreview={true} />}
                  />
                </Route>
                <Route
                  path={routes.collect}
                  element={
                    <CollectPage locale={locale} setLocale={setLocale} />
                  }>
                  <Route path=":surveyId" element={<CollectorList />} />
                  <Route
                    path=":surveyId/:collectorId"
                    element={<CollectSurvey />}
                  />
                </Route>
              </>
            )}
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
