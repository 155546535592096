import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { routes } from '../../constants'
import Loading from '../../components/Loading'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../../api'
import useStore from '../../store'
import SimpleDialog from '../../components/SimpleDialog'
import RespondentCountTags from '../../components/RespondentCountTags'
import { useSnackbar } from 'notistack'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip
} from '@mui/material'
import {
  Delete as DeleteIcon,
  Download as DownloadIcon,
  Edit as EditIcon,
  Lock as LockIcon,
  LockOpen as LockOpenIcon
} from '@mui/icons-material'

const LockButton = (props) => {
  const [locked, setLocked] = useState(props.locked)

  return (
    <IconButton
      sx={{ color: 'primary.main' }}
      onClick={async () => {
        const { locked: val } = await api.collectorLock(props.id, !locked)
        setLocked(val)
      }}>
      {locked ? <LockIcon /> : <LockOpenIcon />}
    </IconButton>
  )
}

const EditIconButton = (props) => {
  const navigate = useNavigate()

  return (
    <Tooltip title={<FormattedMessage id="tooltip.edit_collector" />}>
      <IconButton
        sx={{ color: 'primary.main' }}
        onClick={() =>
          navigate(`${routes.collect}/${props.surveyId}/${props.id}`)
        }>
        <EditIcon />
      </IconButton>
    </Tooltip>
  )
}

const DownloadIconButton = (props) => {
  const [loading, setLoading] = useState(false)
  return (
    <Tooltip title={<FormattedMessage id="tooltip.download_all" />}>
      <IconButton
        sx={{ color: props.color || 'primary.main' }}
        onClick={async () => {
          setLoading(true)
          await api.collectCSV(props.id)
          setLoading(false)
        }}>
        {loading ? (
          <CircularProgress sx={{ color: '#00438e' }} size={18} />
        ) : (
          <DownloadIcon />
        )}
      </IconButton>
    </Tooltip>
  )
}

const LikertDownloadButton = (props) => {
  const [loading, setLoading] = useState(false)
  return (
    <Tooltip title={<FormattedMessage id="tooltip.download_likert" />}>
      <IconButton
        sx={{ color: 'green' }}
        onClick={async () => {
          setLoading(true)
          await api.collectXLSX(props.id)
          setLoading(false)
        }}>
        {loading ? (
          <CircularProgress sx={{ color: 'green' }} size={18} />
        ) : (
          <DownloadIcon />
        )}
      </IconButton>
    </Tooltip>
  )
}

const DemographicDownloadIcon = (props) => {
  const [loading, setLoading] = useState(false)
  return (
    <Tooltip title={<FormattedMessage id="tooltip.download_demographic" />}>
      <IconButton
        sx={{ color: 'blue' }}
        onClick={async () => {
          setLoading(true)
          await api.collectLikert(props.id)
          setLoading(false)
        }}>
        {loading ? (
          <CircularProgress sx={{ color: 'blue' }} size={18} />
        ) : (
          <DownloadIcon />
        )}
      </IconButton>
    </Tooltip>
  )
}

const DeleteIconButton = (props) => {
  return (
    <Tooltip title={<FormattedMessage id="tooltip.delete_collector" />}>
      <IconButton sx={{ color: 'error.main' }} onClick={props.onClick}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  )
}

export const CollectorList = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { surveyId } = useParams()

  const [dialog, setDialog] = useState({ open: false })

  const surveys = useStore((state) => state.surveys)
  const currentSurvey = surveys.find((s) => s.id === parseInt(surveyId))

  const { collectors } = currentSurvey

  if (!currentSurvey) {
    return <Loading />
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
        <Box>
          <FormattedMessage id="collector.show_list" />
          <Box sx={{ color: 'darkred', display: 'inline' }}>
            <code> {currentSurvey.title}</code>
          </Box>
        </Box>
        <Button
          variant="contained"
          onClick={async () => {
            // create new collector and navigate to it
            const { collectorId } = await api.createCollector(surveyId)
            navigate(`${routes.collect}/${surveyId}/${collectorId}`)
          }}>
          <FormattedMessage id="collector.add_new" />
        </Button>
      </Box>
      <Box>
        <List>
          {collectors.map((item) => (
            <ListItem
              key={item.id}
              sx={{
                bgcolor: 'rgb(232,232,232)',
                borderRadius: 2,
                my: 1
              }}>
              <ListItemText
                primary={
                  item.title.trim() === '' || !item.title.trim()
                    ? (item.title = `new-collector-${item.id}`)
                    : item.title
                }
              />
              <RespondentCountTags item={item} />
              <LockButton id={item.id} locked={item.locked} />
              <EditIconButton id={item.id} surveyId={surveyId} />
              <DownloadIconButton id={item.id} />
              <LikertDownloadButton id={item.id} />
              <DemographicDownloadIcon id={item.id} />
              <DeleteIconButton
                id={item.id}
                onClick={() =>
                  setDialog({
                    ...dialog,
                    open: true,
                    title: item.title,
                    id: item.id
                  })
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <SimpleDialog
        content={<FormattedMessage id="collector.confirm_delete" />}
        onConfirm={async () => {
          try {
            await api.deleteCollector(surveyId, dialog.id)
            enqueueSnackbar(
              <FormattedMessage id="collector.collector_deleted" />,
              {
                variant: 'success',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right'
                }
              }
            )
            setDialog({ open: false })
          } catch (error) {
            console.error('Error deleting collector', error)
          }
        }}
        open={dialog.open}
        title={<FormattedMessage id="collector.delete_header" />}
        setOpen={(open) => setDialog({ ...dialog, open })}
      />
    </Box>
  )
}
