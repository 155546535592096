import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie'
import { Navigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import useStore from '../store'
import { isResponseOk } from '../helpers'
import { routes } from '../constants'
import { Box, Button, Input, InputLabel, Typography } from '@mui/material'

const cookies = new Cookies()

export default function Login() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const setIsAuthenticated = useStore((state) => state.setIsAuthenticated)
  const isAuthenticated = useStore((state) => state.isAuthenticated)

  const getSession = () => {
    fetch('/user/session/', {
      credentials: 'same-origin'
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        if (data.isAuthenticated) {
          setIsAuthenticated(true)
        } else {
          setIsAuthenticated(false)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getSession()
  }, [])

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handleUserNameChange = (event) => {
    setUsername(event.target.value)
  }

  const login = (event) => {
    event.preventDefault()
    console.log(cookies.get('csrftoken'))
    fetch('/user/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': cookies.get('csrftoken')
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        username,
        password
      })
    })
      .then(isResponseOk)
      .then((data) => {
        console.log(data)
        setIsAuthenticated(true)
        setUsername('')
        setPassword('')
        setError('')
      })
      .catch((err) => {
        console.log(err)
        setError(<FormattedMessage id="login.incorrect_credentials" />)
      })
  }

  if (!isAuthenticated) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw'
        }}>
        <Box
          sx={{
            px: 4,
            fontSize: 25
          }}>
          PROMPTA <br />
          SURVEY ENGINE
        </Box>
        <Box
          sx={{
            maxWidth: 500,
            maxHeight: 500,
            borderLeft: '10px solid blue',
            px: 4
          }}>
          <Typography
            variant="h5"
            sx={{
              my: 3
            }}>
            <FormattedMessage id="login.login" />
          </Typography>
          <form onSubmit={login}>
            <div className="form-group">
              <InputLabel htmlFor="username">
                <FormattedMessage id="login.username" />
              </InputLabel>
              <Input
                type="text"
                className="form-control"
                id="username"
                name="username"
                value={username}
                onChange={handleUserNameChange}
              />
            </div>
            <Box className="form-group" sx={{ mt: 1 }}>
              <InputLabel htmlFor="password">
                <FormattedMessage id="login.password" />
              </InputLabel>
              <Input
                type="password"
                className="form-control"
                id="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
              />
              <Box
                sx={{
                  pt: 2,
                  pl: 1
                }}>
                {error && (
                  <Typography variant="string" color="error.main">
                    {error}
                  </Typography>
                )}
              </Box>
            </Box>
            <Button
              size="large"
              type="submit"
              className="btn btn-primary"
              sx={{
                my: 1
              }}>
              <FormattedMessage id="login.login" />
            </Button>
          </form>
        </Box>
      </Box>
    )
  }
  return <Navigate to={routes.surveys} />
}
